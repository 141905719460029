import { useEffect, useState } from 'react';
import { Button, Col, Form, List, ListInlineItem, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { CreateProductCategoryForm } from './components/CreateProductCategoryForm';
import { CreateProductTypeForm } from './components/CreateProductTypeForm';
import { CollapseList } from '../../components/Forms/CollapseList';
import { CustomInput } from '../../components/Input/CustomInput';
import Divider from '../../components/Divider';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import { Product, ProductCategory, ProductInput, ProductType } from '../../types';
import { CraftsmanType, Pages } from '../../enums';
import Tooltip from '../../components/Tooltip';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type ProductDetailsProps = {
  type?: 'new' | 'existing';
};

const ProductSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  name: yup.object({
    en: yup.string().required('Main title is required.'),
    de: yup.string().required('Sub title is required.'),
  }),
  priceCent: yup.number().required('This field is required.'),
  shortDescription: yup.object({
    en: yup.string().max(3000, 'Maximum 3000 characters').required('English description is required.'),
    de: yup.string().max(3000, 'Maximum 3000 characters').required('German description is required.'),
  }),
  detailedDescription: yup.object({
    en: yup.string().max(3000, 'Maximum 3000 characters'),
    de: yup.string().max(3000, 'Maximum 3000 characters'),
  }),
  imageUrl: yup.string().required('This field is required.'),
  productType: yup.mixed().required('This field is required.').nullable(),
  productCategory: yup.mixed().required('This field is required.').nullable(),
  tailorType: yup.mixed<CraftsmanType>().oneOf(Object.values(CraftsmanType)).nullable(),
  input: yup.mixed().nullable(),
  active: yup.boolean().default(true),
  displayRank: yup.number(),
});
type ProductSchemaType = yup.InferType<typeof ProductSchema>;

const ProductDetails = ({ type = 'new' }: ProductDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<ProductSchemaType>({
    _id: '',
    number: '',
    name: {
      en: '',
      de: '',
    },
    priceCent: 0,
    shortDescription: {
      en: '',
      de: '',
    },
    detailedDescription: {
      en: '',
      de: '',
    },
    imageUrl: '',
    productType: {
      displayRank: 0,
      _id: '',
    },
    productCategory: {
      displayRank: 0,
      _id: '',
    },
    tailorType: CraftsmanType.Cobbler,
    input: null,
    active: true,
    displayRank: 0,
  });
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [productInputs, setProductInputs] = useState<ProductInput[]>([]);
  const [product, setProduct] = useState<Product[]>([]);
  const [reloadInputs, setReloadInputs] = useState(false);
  const [closeCreateTypesAccordion, setCloseCreateTypesAccordion] = useState(false);
  const [closeCreateCategoriesAccordion, setCloseCreateCategoriesAccordion] = useState(false);
  const [closeCreateInputsAccordion, setCloseCreateInputsAccordion] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  useEffect(() => {
    (async () => {
      const [productTypesResponse, productCategoriesResponse, productInputsResponse, productResponse] = await Promise.all([
        apiRequest(`/product/types`),
        apiRequest(`/product/categories`),
        apiRequest(`/product/inputs`),
        apiRequest(`/product/by-id/${id}`),
      ]);
      const [{ data: types }, { data: categories }, { data: inputs }, { data: product }] = await Promise.all([
        productTypesResponse,
        productCategoriesResponse,
        productInputsResponse,
        productResponse,
      ]);
      setProductTypes(types as ProductType[]);
      setProductCategories(categories as ProductCategory[]);
      setProductInputs(inputs as ProductInput[]);
      setProduct(product as Product[]);
    })();
  }, []);

  const reloadTypes = async (callback: (types: ProductType[]) => void) => {
    const { data: types } = await apiRequest(`/product/types`);
    setProductTypes(types as ProductType[]);
    callback(types);
  };

  const reloadCategories = async (callback: (categories: ProductCategory[]) => void) => {
    const { data: categories } = await apiRequest(`/product/categories`);
    setProductCategories(categories as ProductCategory[]);
    callback(categories);
  };

  useEffect(() => {
    (async () => {
      if (!reloadInputs) return;
      const { data: inputs } = await apiRequest(`/product/inputs`);
      setProductInputs(inputs as ProductInput[]);
      setReloadInputs(false);
    })();
  }, [reloadInputs]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const onTypesClose = () => {
    setCloseCreateTypesAccordion(true);
  };
  const onCategoriesClose = () => {
    setCloseCreateCategoriesAccordion(true);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/product/by-id/${id}`, { signal: abortController.signal });
      const { message } = data;
      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues(data);
      disableLoading();
    } catch (err) {
      handleError('FETCH_PRODUCT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch product by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: ProductSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      valuesCopy.productType = valuesCopy.productType._id;
      valuesCopy.productCategory = valuesCopy.productCategory._id;
      valuesCopy.input = valuesCopy.input._id;
      const { data } = await apiRequest('/product', {
        method: 'POST',
        data: JSON.stringify(valuesCopy),
        signal: abortController.signal,
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Product created');
      navigate(Pages.PRODUCTS);
    } catch (err) {
      handleError('CREATE_PRODUCT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create product', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: ProductSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      const category = values.productCategory;
      const type = { ...values.productType };
      type.category = category._id;
      delete valuesCopy._id;
      delete valuesCopy.number;
      valuesCopy.productType = valuesCopy.productType._id;
      valuesCopy.productCategory = valuesCopy.productCategory._id;
      valuesCopy.productType = valuesCopy.productType.displayRank;
      valuesCopy.input = valuesCopy.input._id;

      const productUpdateResponse = await apiRequest(`/product/${values._id}`, {
        method: 'PUT',
        data: JSON.stringify(valuesCopy),
        signal: abortController.signal,
      });

      const categoryUpdateResponse = await apiRequest(`/product/category/${values.productCategory._id}`, {
        method: 'PUT',
        data: JSON.stringify(category),
        signal: abortController.signal,
      });

      const typeUpdateResponse = await apiRequest(`/product/type/${values.productType._id}`, {
        method: 'PUT',
        data: JSON.stringify(type),
        signal: abortController.signal,
      });

      const productMessage = productUpdateResponse.data.message;
      const categoryMessage = categoryUpdateResponse.data.message;
      const typeMessage = typeUpdateResponse.data.message;

      if (productMessage || categoryMessage || typeMessage) {
        const messages = [productMessage, categoryMessage].filter((msg) => msg);
        if (messages.length > 0) {
          messages.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        }
        action.setSubmitting(false);
        return disableLoading();
      }

      disableLoading();
      toastr.success('Success', 'Product updated');
      navigate(`/products/${id}`);
    } catch (err) {
      handleError('UPDATE_PRODUCT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update product', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<ProductSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Product Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.PRODUCTS} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={ProductSchema} onSubmit={onSubmit}>
        {(formik) => {
          const onProductTypeSelect = (types: ProductType[], _id: string) => {
            formik.setFieldValue(
              'productType',
              types.find((val) => val._id === _id)
            );
          };
          const onProductCategorySelect = (categories: ProductCategory[], _id: string) => {
            formik.setFieldValue(
              'productCategory',
              categories.find((val) => val._id === _id)
            );
          };
          const onProductInputSelect = (_id: string) => {
            if (formik.values.input && formik.values.input._id === _id) {
              formik.setFieldValue('input', null);
            } else {
              formik.setFieldValue(
                'input',
                productInputs.find((val) => val._id === _id)
              );
            }
          };

          return (
            <Form id="create-product" className="d-flex flex-column gap-4">
              <Row>
                <h6>General Information</h6>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Name En"
                    inputProps={{
                      id: 'name.en',
                      name: 'name.en',
                      type: 'text',
                      value: formik.values.name?.en,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-name-example">
                        <img src="/images/product/product-name-example.png" alt="Example" width={400} height={250} />
                      </Tooltip>
                    }
                    error={formik.touched.name?.en && formik.errors.name?.en ? formik.errors.name?.en : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Name De"
                    inputProps={{
                      id: 'name.de',
                      name: 'name.de',
                      type: 'text',
                      value: formik.values.name?.de,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-name-example">
                        <img src="/images/product/product-name-example.png" alt="Example" width={400} height={250} />
                      </Tooltip>
                    }
                    error={formik.touched.name?.de && formik.errors.name?.de ? formik.errors.name?.de : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Price cent"
                    inputProps={{
                      id: 'priceCent',
                      name: 'priceCent',
                      type: 'number',
                      step: 100,
                      min: 0,
                      value: formik.values.priceCent,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-price-cent-example">
                        <img src="/images/product/product-price-cent-example.png" alt="Example" width={400} height={250} />
                      </Tooltip>
                    }
                    error={formik.touched.priceCent && formik.errors.priceCent ? formik.errors.priceCent : ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Row>
                    <p className="mb-2">Display on User App?</p>
                  </Row>
                  <List className="p-0">
                    <ListInlineItem>
                      <Button className={`btn-md ${formik.values.active ? 'checked' : ''}`} onClick={() => formik.setFieldValue('active', true)}>
                        Display
                      </Button>
                    </ListInlineItem>
                    <ListInlineItem>
                      <Button className={`btn-md ${!formik.values.active ? 'checked' : ''}`} onClick={() => formik.setFieldValue('active', false)}>
                        Hide
                      </Button>
                    </ListInlineItem>
                  </List>
                </Col>
                <Col md={3}>
                  <CustomInput
                    label="Repair Type Rank"
                    inputProps={{
                      id: 'displayRank',
                      name: 'displayRank',
                      type: 'number',
                      step: 1,
                      min: 1,
                      value: formik.values?.displayRank,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.displayRank && formik.errors.displayRank ? formik.errors.displayRank : ''}
                  />
                </Col>
                <Col>
                  <Row>
                    <p className="mb-2">Craftsman Type</p>
                  </Row>
                  <List className="p-0">
                    {Object.values(CraftsmanType).map((val) => (
                      <ListInlineItem key={val}>
                        <Button
                          className={`btn-md ${val.toLowerCase() === formik.values.tailorType?.toLowerCase() ? 'checked' : ''}`}
                          onClick={() => formik.setFieldValue('tailorType', val)}
                        >
                          {val}
                        </Button>
                      </ListInlineItem>
                    ))}
                  </List>
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6>Description</h6>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Short Description En"
                    inputProps={{
                      id: 'shortDescription.en',
                      name: 'shortDescription.en',
                      type: 'textarea',
                      value: formik.values.shortDescription?.en,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-short-description-example">
                        <img src="/images/product/product-short-description-example.png" alt="Example" width={400} height={250} />
                      </Tooltip>
                    }
                    error={formik.touched.shortDescription?.en && formik.errors.shortDescription?.en ? formik.errors.shortDescription?.en : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Short Description De"
                    inputProps={{
                      id: 'shortDescription.de',
                      name: 'shortDescription.de',
                      type: 'textarea',
                      value: formik.values.shortDescription?.de,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-short-description-example">
                        <img src="/images/product/product-short-description-example.png" alt="Example" width={400} height={250} />
                      </Tooltip>
                    }
                    error={formik.touched.shortDescription?.de && formik.errors.shortDescription?.de ? formik.errors.shortDescription?.de : ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Detailed Description En"
                    inputProps={{
                      id: 'detailedDescription.en',
                      name: 'detailedDescription.en',
                      type: 'textarea',
                      value: formik.values.detailedDescription?.en,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-detailed-description-example">
                        <img src="/images/product/product-detailed-description-example.png" alt="Example" width={400} height={400} />
                      </Tooltip>
                    }
                    error={formik.touched.detailedDescription?.en && formik.errors.detailedDescription?.en ? formik.errors.detailedDescription?.en : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Detailed Description De"
                    inputProps={{
                      id: 'detailedDescription.de',
                      name: 'detailedDescription.de',
                      type: 'textarea',
                      value: formik.values.detailedDescription?.de,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="product-detailed-description-example">
                        <img src="/images/product/product-detailed-description-example.png" alt="Example" width={400} height={400} />
                      </Tooltip>
                    }
                    error={formik.touched.detailedDescription?.de && formik.errors.detailedDescription?.de ? formik.errors.detailedDescription?.de : ''}
                  />
                </Col>
              </Row>
              <Divider />
              <CollapseList
                items={productCategories}
                listItems={
                  !!productCategories && !!productCategories.length
                    ? productCategories.map(({ _id, title }) => (
                        <ListInlineItem key={_id} className="mb-2">
                          <Button
                            className={`btn-md ${
                              formik.values.productCategory && _id.toLowerCase() === formik.values.productCategory?._id.toLowerCase() ? 'checked' : ''
                            }`}
                            onClick={() => onProductCategorySelect(productCategories, _id)}
                          >
                            {title?.de}
                          </Button>
                        </ListInlineItem>
                      ))
                    : []
                }
                addon={
                  <Tooltip placement="bottom" target="product-category-example" rounded>
                    <img src="/images/product/category/product-category-example.png" alt="Example" width={400} height={400} />
                  </Tooltip>
                }
                accordionListHeader="Select existing Product Category"
                accordionFormHeader="Create Product Category"
                closeAccordion={closeCreateCategoriesAccordion}
                setCloseAccordion={setCloseCreateCategoriesAccordion}
              >
                <CreateProductCategoryForm
                  initialValues={formik.values.productCategory}
                  onCreation={async (id?: string) => {
                    await reloadCategories((categories) => {
                      if (id) {
                        onProductCategorySelect(categories, id);
                      }
                      onCategoriesClose();
                    });
                  }}
                />
              </CollapseList>
              <Col md={3}>
                <CustomInput
                  label="Product Category Rank"
                  inputProps={{
                    id: 'productCategory.displayRank',
                    name: 'productCategory.displayRank',
                    type: 'number',
                    step: 1,
                    min: 1,
                    value: formik.values.productCategory.displayRank,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.displayRank && formik.errors.displayRank ? formik.errors.displayRank : ''}
                />
              </Col>
              <CollapseList
                items={productTypes}
                listItems={
                  !!productTypes && !!productTypes.length
                    ? productTypes.map(({ _id, title }) => (
                        <ListInlineItem key={_id} className="mb-2">
                          <Button
                            className={`btn-md ${
                              formik.values.productType && _id.toLowerCase() === formik.values.productType?._id.toLowerCase() ? 'checked' : ''
                            }`}
                            onClick={() => onProductTypeSelect(productTypes, _id)}
                          >
                            {title?.de}
                          </Button>
                        </ListInlineItem>
                      ))
                    : []
                }
                addon={
                  <Tooltip placement="bottom" target="product-type-example" rounded>
                    <img src="/images/product/type/product-type-example.png" alt="Example" width={400} height={400} />
                  </Tooltip>
                }
                accordionListHeader="Select existing Product Type"
                accordionFormHeader="Create Product Type"
                closeAccordion={closeCreateTypesAccordion}
                setCloseAccordion={setCloseCreateTypesAccordion}
              >
                <CreateProductTypeForm
                  initialValues={formik.values.productType}
                  productCategories={productCategories}
                  onCreation={async (id: string) => {
                    await reloadTypes((types) => {
                      onProductTypeSelect(types, id);
                      onTypesClose();
                    });
                  }}
                />
              </CollapseList>
              <Col md={3}>
                <CustomInput
                  label="Product Type Rank"
                  inputProps={{
                    id: 'productType.displayRank,',
                    name: 'productType.displayRank',
                    type: 'number',
                    step: 1,
                    min: 1,
                    value: formik.values.productType?.displayRank,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.displayRank && formik.errors.displayRank ? formik.errors.displayRank : ''}
                />
              </Col>
              <CollapseList
                items={productInputs}
                listItems={
                  !!productInputs && !!productInputs.length
                    ? productInputs.map(({ _id, text, type, default: defaultValue, unit, required }) => (
                        <ListInlineItem key={_id} className="mb-2">
                          <Button
                            className={`btn-md ${formik.values.input && formik.values.input._id === _id.toLowerCase() ? 'checked' : ''}`}
                            onClick={() => onProductInputSelect(_id)}
                          >
                            {`${type}, ${defaultValue ? defaultValue : ''}${unit ? unit + ',' : ''} ${required ? 'Is required' : 'Not required'}. ${text}`}
                          </Button>
                        </ListInlineItem>
                      ))
                    : []
                }
                addon={
                  <Tooltip placement="bottom" target="product-input-example" rounded>
                    <img src="/images/product/input/product-input-example.png" alt="Example" width={400} height={400} />
                  </Tooltip>
                }
                accordionListHeader="Select existing Product Input"
                closeAccordion={closeCreateInputsAccordion}
                setCloseAccordion={setCloseCreateInputsAccordion}
              />
              <Divider />
              <Row>
                <CustomInput
                  label="Product Image (upload to AWS, paste the URL)"
                  inputProps={{
                    id: 'imageUrl',
                    name: 'imageUrl',
                    type: 'text',
                    value: formik.values.imageUrl,
                    onChange: formik.handleChange,
                  }}
                  addon={
                    <Tooltip placement="bottom" target="product-image-example">
                      <img src="/images/product/product-image-example.png" alt="Example" width={400} height={250} />
                    </Tooltip>
                  }
                  error={formik.touched.imageUrl && formik.errors.imageUrl ? formik.errors.imageUrl : ''}
                />
              </Row>
              <Row className="mt-5">
                <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                  <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                    {type === 'new' ? 'Create' : 'Update'} Product {loading && <Spinner size="sm" color="secondary" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export { ProductDetails };
